.invoice {
    color: #2c4758;
        .accordion {
            
            // border: 2px solid #2c4758;
            --bs-accordion-color: #2c4758 !important;
            --bs-accordion-bg: #fff;
            --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
            --bs-accordion-border-color: #2c4758;
            --bs-accordion-border-width: 0.5px;
            --bs-accordion-border-radius: 0.375rem;
            --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
            --bs-accordion-btn-padding-x: 1.25rem;
            --bs-accordion-btn-padding-y: 1rem;
            --bs-accordion-btn-color: #2c4758;
            --bs-accordion-btn-bg: #fff;
            
            --bs-accordion-btn-icon-width: 1.25rem;
            --bs-accordion-btn-icon-transform: rotate(-180deg);
            --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
            
            --bs-accordion-btn-focus-border-color: #fff;
            --bs-accordion-btn-focus-box-shadow: #fff;
            --bs-accordion-body-padding-x: 1.25rem;
            --bs-accordion-body-padding-y: 1rem;
            --bs-accordion-active-color: #fff;
            --bs-accordion-active-bg: #2c4758;
        
            .accordion_header {
                background-color: #2c4758;
                color: #fff;
                div {
                    white-space: nowrap !important; /* Запрещаем перенос строк */
                    overflow: hidden !important; /* Обрезаем все, что не помещается в область */
                    text-overflow: ellipsis !important; /* Добавляем многоточие */
                }
            }
            // .accordion_body{
            //     color: #2c4758;
            // }
        }
    }
