@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,700;1,100&family=Nunito:wght@300;400;500;600;700;800&display=swap');


$grey: #a8c1c8 ;
$lblue: #7da6b8;
$blue: #4e7890;
$dblue: #2c4758;
$dgreen: #3a5c44;
$orange: #e78941;



.btn-primary {
  --bs-btn-color: white !important;
  --bs-btn-bg: #e78941 !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #e78941d8 !important;
  --bs-btn-hover-border-color: #e78941d8 !important;
  --bs-btn-active-color: white !important;
  --bs-btn-active-bg: #e78941 !important;
  --bs-btn-active-border-color: #e78941 !important;
  --bs-btn-disabled-color: white !important;
  --bs-btn-disabled-bg: #e78941b6 !important;
  --bs-btn-disabled-border-color: #e78941b6 !important;
}
.btn-secondary {
  --bs-btn-color: white !important;
  --bs-btn-bg: #e78941 !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #e78941d8 !important;
  --bs-btn-hover-border-color: #e78941d8 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: white !important;
  --bs-btn-active-bg: #e78941 !important;
  --bs-btn-active-border-color: #e78941 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white !important;
  --bs-btn-disabled-bg: #e78941 !important;
  --bs-btn-disabled-border-color: #e78941 !important;
  --bs-btn-font-weight: 600 !important;
  --bs-btn-font-size: 20px !important;
}
.btn-light {
  --bs-btn-color: #2c4758 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff !important;
  --bs-btn-hover-color: #000 !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-border-color: #e78941 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: white !important;
  --bs-btn-active-bg: #e78941 !important;
  --bs-btn-active-border-color: #e78941 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: white !important;
  --bs-btn-disabled-bg: #e78941b6 !important;
  --bs-btn-disabled-border-color: #e78941b6 !important;
}
body::-webkit-scrollbar {
  width: 7px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  background: #fff;        /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: #a8c1c8;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 1px solid white;  /* padding вокруг плашки */
}
.t-w {
  color: #fff;
}
.t-b {
  color: #2c4758;
}
.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: 0;
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Josefin Sans', sans-serif !important;
  font-family: 'Nunito', sans-serif !important;
}
a {
  color: #2c4758 !important;
  padding: 0;
  text-decoration: none !important;
}
.text-full-width {
  text-align: justify;
}





// Пульсации
/* h1 {
  animation: pulsate 2.5s infinite alternate;     
} */

// неоновый текст с мерцанием
// .neonText {
//   @keyframes flicker {
//     0%, 18%, 22%, 25%, 53%, 57%, 100% {
//       color: #fff;
//       text-shadow:
//           0 0 7px #fff,
//           0 0 10px #fff,
//           0 0 21px #fff,
//           0 0 42px #0fa,
//           0 0 82px #0fa,
//           0 0 92px #0fa,
//           0 0 102px #0fa,
//           0 0 151px #0fa;
//     }
//   }
// }