.root {
  li {
    display: inline-block;

    a {
      text-align: center; 
      width: 42px;
      line-height: 42px;
      height: 42px;

      border: 1px solid #00b4b6;
      border-radius: 100px;
      margin-right: 10px;
      cursor: pointer;
      display: inline-block;
      color: #00b4b6 !important;

      &:hover {
        background-color: #00b4b6;
        color: white !important;
      }
    }
  }

  :global {
    .selected {
      a {
        background-color: #00b4b6;
        color: white !important;
      }
    }
  }
}
