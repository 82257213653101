footer {
    padding: 0 30px;
    background-color: #2c4758;
    color: #fff;
    a {
        color: #fff !important;
        white-space: nowrap !important; /* Запрещаем перенос строк */
          overflow: hidden !important; /* Обрезаем все, что не помещается в область */
          text-overflow: ellipsis !important; /* Добавляем многоточие */
        &:hover {
            color: #a8c1c8 !important;
        }
    }
    
}