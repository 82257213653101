@import url(https://fonts.googleapis.com/css?family=Roboto:500);
// Гугловская кнопка
.google_btn {
    transition: 0.2s;
    cursor: pointer;
    // .google_icon_wrapper {
    //   position: absolute;
    //   margin-top: 1px;
    //   margin-left: 1px;
    //   width: 50px;
    //   height: 50px;
    //   border-radius: 2px;
    //   background-color: white;
    // }
    .google_icon {
      width: 35px;
      height: 35px;
    }
    .btn_text {
      font-size: 22px !important;
      font-family: "Roboto";
    }
    &:hover {
      scale: 1.05;
    }
    &:active {
      background: white;
    }
  }