header {
    border-bottom: #2c4758 1px solid;
    background-color: #fff;
    .badge {
        background-color: #e78941 !important;
        top: 20px !important;
    }
    .menu {
        border-top: #2c4758 1px solid;
        background-color: #2c4758;
        .nav_item {
            white-space: nowrap !important;
            color: white;
            border: transparent 8px solid;
            &:hover {
                color: #e78941;
                // background-color: #c7d5db63;
            }
        }
        .nav_link_active {
            border-top: #a8c1c8 8px solid;
            border-bottom: #a8c1c8 8px solid;
        }
    }
}
.sandwich {
    border: #2c4758 1px solid;
    background-color: #2c4758;
}
.offcanvas {
    color: #fff !important;
    span {
        color: #fff !important;
    }
}
.dropdown_menu {
    --bs-dropdown-bg: #fff !important;
    
    
    --bs-dropdown-link-hover-color: #2c4758 !important;
    --bs-dropdown-link-hover-bg: #e9ecef !important;
    --bs-dropdown-link-active-color: #fff !important;
    .dropdown_item {
        display: block;
        width: 100%;
        padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
        clear: both;
        font-weight: 400;
        color: #2c4758;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        &:hover {
            background-color: #e9ecef;
        }
        &:active {
            background-color: #2c4758;
        }
        &:active span {
            color: white !important;
        }
    }
    
}