.title {
    color: #2c4758;
}
.account {
margin-bottom: 50px;
      .nav {
        
        padding: 50px 0;
        margin: 0 auto;
        // height: 700px !important;
        background-color: #2c4758;

        .nav_link {
          padding: 30px 50px;
          color: #fff !important;
          white-space: nowrap !important; /* Запрещаем перенос строк */
          overflow: hidden !important; /* Обрезаем все, что не помещается в область */
          text-overflow: ellipsis !important; /* Добавляем многоточие */
          &:hover {
            background-color: #c7d5db63;
          }
        }
        
         
        .nav_link_active {
          background-color: #c7d5db63;
        }
        .nav_last_link_active {
          background-color: #c7d5db63;
          // border-radius: 0 0 50px 0 ;
        }
      }
      
    }

    .text-truncate{
        
      }
