.root {
  padding: 100px;
  max-width: 750px;
  margin: 0 auto;
  text-align: center;

  span {
    font-size: 64px;
  }
}

.description {
  font-size: 22px;
}
