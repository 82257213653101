.device {
    .accordion {
        --bs-accordion-color: #2c4758;
        --bs-accordion-bg: #fff;
        
        --bs-accordion-border-color: #fff;
        --bs-accordion-border-width: 1px;
        --bs-accordion-border-radius: 0.375rem;
        --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
        --bs-accordion-btn-padding-x: 1.25rem;
        --bs-accordion-btn-padding-y: 1rem;
        --bs-accordion-btn-color: #fff;
        --bs-accordion-btn-bg: #2c4758;
        --bs-accordion-btn-icon-width: 1.25rem;
        --bs-accordion-btn-icon-transform: rotate(-180deg);
        --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
        
        --bs-accordion-btn-focus-border-color: none;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 .5rem #fff;
        --bs-accordion-body-padding-x: 1.25rem;
        --bs-accordion-body-padding-y: 1rem;
        --bs-accordion-active-color: #fff;
        --bs-accordion-active-bg: #2c4758;
        .device_topics, .device_info {
            div {
                white-space: nowrap !important; /* Запрещаем перенос строк */
                overflow: hidden !important; /* Обрезаем все, что не помещается в область */
                text-overflow: ellipsis !important; /* Добавляем многоточие */
            }
        }
        }
        .accordion_button_device {
    
        }
    }
