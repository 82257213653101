.cart {
    color: #2c4758;
    .item_name {
        color: #2c4758 !important;
    }
    .row {
        .button {
        }
    }
}
.btn_buyer {
    border-color: #e78941 !important;
}